import tw, { styled, css } from "twin.macro";

export const AppStyles = tw.div`font-sans w-full pt-8 lg:pt-12 2xl:pt-16`;

export const HeaderStyles = tw.header`font-sans mx-auto w-full px-8 py-4 shadow-md lg:p-0 lg:py-6 fixed bg-white z-30`;

export const Title = tw.h1`max-w-sm 2xl:max-w-lg text-3xl my-4 lg:my-8 lg:text-5xl 2xl:text-6xl font-semibold `;

export const H1 = tw.h1`text-5xl my-4 lg:text-8xl font-extrabold font-title`;

export const H2 = tw.h2`text-3xl lg:text-5xl font-extrabold font-title`;

export const H3 = tw.h3`text-2xl lg:text-4xl font-extrabold font-title`;

export const SerifSubtitle = tw.div`uppercase text-xl lg:text-2xl font-extrabold font-title tracking-wider`;

export const Subtitle = tw.div`uppercase lg:text-xl  tracking-widest`;

export const Body = tw.p` max-w-prose mx-auto whitespace-pre-line leading-relaxed`;

export const A = tw.a` font-bold text-teal hover:text-navy`;

export const TextLink = tw.a` font-bold text-rust underline leading-relaxed`;

export const P = styled.p(
	({ maxWidth }: { maxWidth: boolean }) => [
		tw`text-base lg:text-lg  mb-4`,
		maxWidth && tw`max-w-lg`,
	]
);

export const ContentSection = tw.section`container mx-auto w-10/12 lg:w-9/12 grid place-items-center gap-x-8 gap-y-12 lg:gap-8 py-16 lg:py-24`;

export const BtnContainer = tw.div`grid gap-4 justify-items-start items-center w-72 max-w-xs`;

export const ButtonBlue = tw.button`text-sm w-48 text-center uppercase text-beige-light tracking-wider font-bold  py-2 px-4 bg-teal transition hover:bg-navy focus:outline-none`;

export const ButtonOrange = tw.button`text-sm w-48 text-center uppercase text-beige-light tracking-wider font-bold  py-2 px-4 bg-rust transition focus:outline-none`;

export const ButtonBeige = tw.button`text-sm w-52 text-center uppercase text-beige tracking-wider font-bold  py-2 px-4 border border-beige transition hover:bg-beige hover:text-black focus:outline-none`;

export const ButtonGreen = tw.button`text-sm w-52 text-center uppercase text-pine tracking-wider font-bold  py-2 px-4 border border-pine transition hover:bg-pine hover:text-beige-light focus:outline-none`;

export const ButtonWhite = tw.button`text-sm w-52 text-center uppercase text-white tracking-wider font-bold  py-2 px-4 border border-white transition hover:bg-white hover:text-pine focus:outline-none`;

export const ButtonAvailability = tw.button`text-sm w-64 text-center uppercase text-beige-light tracking-wide font-bold  px-4 bg-brown transition hover:bg-black focus:outline-none`;

export const AvailableStyle = tw`text-sm w-64 text-center uppercase text-beige-light tracking-wide font-bold  px-4 bg-brown transition hover:bg-black focus:outline-none`;

export const ButtonMap = tw.button`text-sm w-full md:w-52 text-center uppercase text-pine bg-beige-light tracking-wider font-bold py-2 px-4 border border-teal transition hover:bg-teal hover:text-beige focus:outline-none`;

export const Dropdown = styled.select(
	({ open }: { open: boolean }) => [
		tw`font-bold  text-pine w-52 py-2 px-4 border border-pine focus:outline-none transition appearance-none`,
		open && tw`text-beige bg-pine hover:opacity-80 `,
		css`
			&:after {
				content: "";
				position: absolute;
				right: 2px;
				border: 6px solid transparent;
				border-color: #fff transparent transparent transparent;
			}
		`,
	]
);

export const DropdownOption = tw.option`uppercase text-black bg-white hover:bg-beige hover:text-black hover:font-bold focus:outline-none appearance-none`;

export const ServiceContainer = tw.div`container w-10/12 lg:w-9/12 mx-auto flex justify-center items-center lg:items-start flex-col lg:flex-row`;

export const ServiceText = tw.p`max-w-3xl`;
